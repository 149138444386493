import { AccessToken } from "./access-token";
import { makeAutoObservable } from "mobx";
import { User } from "./user.models";

const ONLINE_ADMIN_ROLE = 'online_admin';
const GLOBAL_ADMIN_ROLE = 'admin';
const COORDINATOR_ROLE = "srm_coordinator";

export class AuthStore {
    public accessToken?: AccessToken;

    public isLogged?: boolean = undefined;

    public userId?: number;

    public userEmail?: string;

    public displayName?: string;

    public lkLang: string = 'ru';

    public lkSessionId?: number;

    public sub?: string;

    public picture?: string;

    public lkUserData?: User | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    private setDisplayName(displayName?: string) {
        this.displayName = displayName;
    }

    private setIsLogged(isLogged: boolean) {
        this.isLogged = isLogged;
    }

    private setUserId(userId?: number) {
        this.userId = userId;
    }

    private setUserEmail(userEmail?: string) {
        this.userEmail = userEmail;
    }

    private setLkLang(lang: string) {
        this.lkLang = lang.toLowerCase();
    }

    private setLkSessionId(lkSessionId?: number) {
        this.lkSessionId = lkSessionId;
    }

    private setSub(sub?: string) {
        this.sub = sub;
    }

    private setPicture(picture?: string) {
        this.picture = picture;
    }

    private setTokens(accessToken?: AccessToken) {
        this.accessToken = accessToken;
    }

    public updateFromAccessToken(accessToken?: AccessToken): AuthStore {
        if (!accessToken?.value) {
            this.setTokens(undefined);
            this.setIsLogged(false);
            this.setUserId(undefined);
            this.setUserEmail(undefined);
            this.setDisplayName(undefined);
            this.setLkLang('ru');
            this.setLkSessionId(undefined);
            this.setSub(undefined);
            this.setPicture(undefined);
        } else {
            this.setTokens(accessToken);
            this.setIsLogged(true);
            this.setUserId(accessToken.value?.lk_user_id);
            this.setUserEmail(accessToken.value?.email);
            this.setDisplayName(accessToken.value?.displayName);
            this.setLkLang(accessToken.value?.lang);
            this.setLkSessionId(accessToken.value?.lk_session_id);
            this.setSub(accessToken.value?.sub);
            this.setPicture(accessToken.value?.picture);
        }

        return this;
    }

    get accessTokenRaw(): (string | undefined) {
        return this.accessToken?.raw;
    };

    get isOnlineAdmin(): boolean {
        return this.accessToken?.value?.authorities?.includes(ONLINE_ADMIN_ROLE) ?? false;
    }

    get isGlobalAdmin(): boolean {
        return this.accessToken?.value?.authorities?.includes(GLOBAL_ADMIN_ROLE) ?? false;
    }

    get isCoordinator(): boolean {
        return this.accessToken?.value?.authorities?.includes(COORDINATOR_ROLE) ?? false;
    }

    get isInternalUser(): boolean {
        return this.isCoordinator || this.isGlobalAdmin || this.isOnlineAdmin;
    }

    public updateFromJpa(data: User | null) {
        this.lkUserData = data;
    }

    public get nameAndSurname(): ({ firstName?: string, lastName?: string } | undefined) {
        if (this.isLogged && this.displayName) {
            const splitDisplayName = this.displayName.split(" ");
            return {
                firstName: splitDisplayName[0] ?? undefined,
                lastName:
                    splitDisplayName[1] !== undefined
                        ? splitDisplayName[2] !== undefined
                            ? splitDisplayName[2]
                            : splitDisplayName[1] ?? undefined
                        : undefined
            };
        }

        return undefined;
    }
}
