import axios from "axios";

import { HttpCodes } from "../../utils/http-codes";
import { getHostUrl, getLKUrl, getScope } from "../../../environment";
import { User } from "./user.models";

const TENANT =
    getScope(undefined) === "PARTNER" ? "cfp_partner" :
        getScope(undefined) === "MEETUP" ? "cfp_meetup" :
            "cfp";

const baseURL = `${getHostUrl()}api/v2/auth`;

const api = axios.create({
    baseURL,
});

export type OAuthProvider = "google" | "yandex";

export const publicEndpointLogin = (returnPage?: string) => `${baseURL}/login/${TENANT}/${(returnPage ? `?redirect_uri=${returnPage}` : ``)}`;
export const publicEndpointOAuth = (provider: OAuthProvider, returnPage: string) => `${getLKUrl()}api/v1/oauth2/authorization/${provider}?on_success=${encodeURIComponent(publicEndpointLogin(returnPage))}`;

const privateEndpoints = {
    silentLogin: () => `login/${TENANT}?prompt=none`,
    logout: `logout?tenant=${TENANT}`,
    verify: `verify/${TENANT}?tenant`,
}

export class AuthApiService {
    public logout(): Promise<Response> {
        return api.get(privateEndpoints.logout)
    }

    public verify(): Promise<boolean> {
        return api.get(privateEndpoints.verify, { withCredentials: true })
            .then(response => response.status !== HttpCodes.UNAUTHORIZED)
            .catch(error => error.response.status !== HttpCodes.UNAUTHORIZED)
    }

    public silentLogin(): Promise<Response> {
        return api.get(privateEndpoints.silentLogin(), { withCredentials: true });
    }

    public async getUserInfoFromJpa(token: string): Promise<User | null> {
        const lk = axios.create({
            baseURL: `${getLKUrl()}api/v1/`,
            headers: { authorization: `Bearer ${token}` }
        });

        const command = {
            command: "user.get_info"
        };

        return lk.post("command", command)
            .then(response => {
                if (response.data?.errors?.[0]?.message !== undefined) {
                    throw new Error(response.data?.errors?.[0]?.message);
                } else {
                    return response;
                }
            })
            .then(response => response.data?.response?.user as User)
            // throw any exception as null
            .catch(() => null)
    }
}
