import { AccessToken } from "./access-token";

import { AuthApiService } from "./auth.api.service";

import { AuthStore } from "./AuthStore";

import { isLocalEnvironment } from "../../../environment";
import { info } from "next/dist/build/output/log";
import { User } from "./user.models";

export const NUMBER_OF_VERIFICATION_ATTEMPTS = 1;

class AuthService {
    private accessToken: AccessToken;
    private onSilentLogin?: () => void;
    private onAnywaySilentLogin?: () => void;

    private currentVerificationAttemptsCount = 0;
    private currentGetLkUserInfoAttemptsCount = 0;

    constructor(
        private readonly authApiService: AuthApiService,
        private readonly authStore: AuthStore,
    ) {
        this.accessToken = AccessToken.receive();
        this.authStore.updateFromAccessToken(this.accessToken);
    }

    public zeroOutCurrentVerificationAttemptsCount(): void {
        this.currentVerificationAttemptsCount = 0;
    }

    public refreshAuthData(): Promise<boolean> {
        this.accessToken = AccessToken.receive();

        if (!this.accessToken.hasValue) {
            return this.tryVerify();
        }

        if (this.accessToken.isExpired) {
            info("[refreshAuthData] token is expired soon - refresh");
            return this.tryVerify();
        }

        this.authStore.updateFromAccessToken(this.accessToken);
        return Promise.resolve(true);
    }

    public checkTokenAndRenew(): Promise<boolean> {
        if (this.accessToken) {
            if (this.accessToken.isExpired) {
                info("[checkTokenAndRenew] token is expired soon - refresh");
                return this.tryVerify();
            }
            return Promise.resolve(true);
        }
        return Promise.resolve(true);
    }

    public logout(): Promise<Response> {
        return this.authApiService.logout();
    }

    public tryVerify(): Promise<boolean> {
        if (isLocalEnvironment) {
            return Promise.resolve(true);
        }

        if (this.currentVerificationAttemptsCount >= NUMBER_OF_VERIFICATION_ATTEMPTS) {
            return Promise.resolve(false);
        }

        this.currentVerificationAttemptsCount++;

        return this.authApiService.verify()
            .then(result => {
                if (!result) {
                    return this.authApiService.silentLogin()
                        .then(() => {
                            if (AccessToken.receive().hasValue) {
                                this.onAnywaySilentLogin?.();
                                return true;
                            }
                            return false;
                        })
                        .catch(() => {
                            return false;
                        });
                }

                return true;
            })
            .then((silentLoginResult) => {
                this.accessToken = AccessToken.receive();
                this.authStore.updateFromAccessToken(this.accessToken);

                if (silentLoginResult) {
                    this.onSilentLogin?.();
                    return true;
                }

                return false;
            });
    }

    public reset() {
        this.authStore.updateFromAccessToken(undefined);
    }

    public async updateDataFromJpa(force?: boolean) {
        if (this.accessToken.raw && (this.currentGetLkUserInfoAttemptsCount === 0 || force)) {
            this.currentGetLkUserInfoAttemptsCount++;
            const data: (User | null) = await this.authApiService.getUserInfoFromJpa(this.accessToken.raw);
            this.authStore.updateFromJpa(data);
        }
    }
}

export { AuthService }
