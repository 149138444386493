import { Draft, DraftActorsSpeaker, DraftScope } from "./models/draft.models";
import { StepFirstModel, StepSecondModel, StepThirdModel } from "./models/forms.models";
import { removeUndefined } from "../../utils/common";
import { Lang } from "../common/common.models";

export const getInitDraft = (project: string, version: string, lang: Lang, scope?: DraftScope): Draft => ({
    project,
    version,
    stage: 0,
    lang,
    scope: scope ?? "REGULAR",
    consents: {
        adsAndSubscriptionsOptionalConsentAccepted: undefined
    }
});

// const updatePrefixFields = (data: StepFirstModel): StepFirstModel => {
//     if (data.twitter) {
//         data.twitter = `@${data.twitter.replaceAll("@", "")}`;
//     }
//     if (data.phone) {
//         data.phone = `+${data.phone.replaceAll("+", "")}`;
//     }
//
//     return data;
// }

export const mergeDraftWithStepFirst = (draft: Draft | null, data: StepFirstModel): Draft => {
    const speaker: DraftActorsSpeaker = {
        ...draft?.actors?.speaker ?? {},
        ...removeUndefined(data)
    };

    return {
        ...draft ?? {},
        actors: {
            ...draft?.actors ?? {},
            speaker
        },
        stage: 1
    };
}

export const mergeDraftWithStepSecond = (draft: Draft | null, data: StepSecondModel): Draft => {
    return {
        ...draft ?? {},
        activity: {
            ...draft?.activity ?? {},
            ...removeUndefined(data),
            coSpeakerCandidates: undefined
        },
        coSpeakerCandidates: data.coSpeakerCandidates,
        stage: 2
    }
}

export const mergeDraftWithStepThird = (draft: Draft | null, data: StepThirdModel): Draft => {
    return {
        ...draft ?? {},
        activity: {
            ...draft?.activity ?? {},
            ...removeUndefined(data),
        },
        consents: removeUndefined({
            adsAndSubscriptionsOptionalConsentAccepted: data.adsAndSubscriptionsOptionalConsentAccepted
        }),
        stage: 100
    }
}

export const draftComparerDesc = (a, b) => b.id - a.id;
